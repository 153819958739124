import { addPropertyControls, ControlType } from "framer"

export default function Component_input_search(props) {
    // - Find inspiration: https://www.framer.com/developers/

    const containerStyle = {
        position: "relative",
        display: "flex",
        alignItems: "center",
    }

    const inputStyle = {
        backgroundColor: "#F0F0F0",
        outline: "none",
        height: "48px",
        width: "100%",
        paddingLeft: "50px",
        fontFamily: "Inter",
        fontStyle: "normal",
        fontWeight: "500",
        lineHeight: "16px",
        fontSize: "14px",
        color: "#939393",
        border: "1px solid #EDEDED",
        borderRadius: "2px",
    }

    // function handlerChange() {
    //     let main = document.querySelector(".collection-blog")
    //     let cards = main.querySelectorAll(".blog-card")
    //     let titles = main.querySelectorAll(".collection-blog__title h3")

    //     console.log(titles)
    //     for (let i = 0; i < titles.length; i++) {
    //         const element = titles[i]
    //     }
    // }

    function filterBlogsCards(e) {
        const main = document.querySelector(".collection-blog")
        const collectionBlog = main.querySelector(".collection-blog")
        const cardItems = main.querySelectorAll(".blog-card")

        let inputValue = e.target.value.toLowerCase()

        for (let i = 0; i < cardItems.length; i++) {
            const element = cardItems[i]
            const title = element.querySelector(
                ".collection-blog__title .framer-text"
            )

            console.log(title.innerText)
            console.log(title.innerText.toLowerCase())
            console.log(title.innerText.toLowerCase().search(inputValue))

            if (title.innerText.toLowerCase().search(inputValue) != "-1") {
                console.log("+")
                element.parentNode.parentNode.style.display = null
            } else if (
                title.innerText.toLowerCase().search(inputValue) == "-1"
            ) {
                console.log("-")
                element.parentNode.parentNode.style.display = "none"
            }
        }
    }

    return (
        <div style={containerStyle}>
            <div
                style={{
                    position: "absolute",
                    left: "18px",
                    display: "flex",
                    alignItems: "center",
                    pointerEvents: "none",
                    color: "#626262",
                }}
            ></div>
            <svg
                xmlns="http://www.w3.org/2000/svg"
                focusable="false"
                viewBox="0 0 24 24"
                color="var(--token-4afff1e1-ad66-4449-a126-34071669c871, rgb(17, 17, 17))"
                style={{
                    position: "absolute",
                    left: "16px",
                    userSelect: "none",
                    width: "24px",
                    height: "24px",
                    display: "inline-block",
                    fill: "var(--token-4afff1e1-ad66-4449-a126-34071669c871, rgb(17, 17, 17))",
                    flexShrink: "0",
                }}
            >
                <path d="M15.5 14h-.79l-.28-.27C15.41 12.59 16 11.11 16 9.5 16 5.91 13.09 3 9.5 3S3 5.91 3 9.5 5.91 16 9.5 16c1.61 0 3.09-.59 4.23-1.57l.27.28v.79l5 4.99L20.49 19l-4.99-5zm-6 0C7.01 14 5 11.99 5 9.5S7.01 5 9.5 5 14 7.01 14 9.5 11.99 14 9.5 14z"></path>
            </svg>
            <input
                type={props.type}
                style={inputStyle}
                placeholder={props.placeholder}
                onChange={(e) => {
                    props.function === "b" ? filterBlogsCards(e) : null
                }}
            />
        </div>
    )
}

// Styles are written in object syntax
// Learn more: https://reactjs.org/docs/dom-elements.html#style

// Component_input.defaultProps = {
//     placeholder: "Search on the Blog",
// }

addPropertyControls(Component_input_search, {
    placeholder: {
        text: "Placeholder",
        defaultValue: "Placeholder",
        type: ControlType.String,
    },
    type: {
        text: "text",
        defaultValue: "text",
        type: ControlType.String,
    },
    function: {
        type: ControlType.Enum,
        defaultValue: "Clear",
        displaySegmentedControl: true,
        segmentedControlDirection: "vertical",
        options: ["a", "b", "c"],
        optionTitles: ["Clear", "Blog", "Templates"],
    },
})
